import * as React from 'react';
import {
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableBody,
    Typography,
    LinearProgress,
    CircularProgress,
    Button,
    Grid,
    Box
} from '@mui/material';
import { green, red, grey, orange } from '@mui/material/colors';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import LoopSharpIcon from '@mui/icons-material/LoopSharp';
import AssignmentSharpIcon from '@mui/icons-material/AssignmentSharp';
import { BlockOutlined } from '@mui/icons-material';
import CancelOutlineIcon from '@mui/icons-material/CancelOutlined';
import GetAppSharpIcon from '@mui/icons-material/GetAppSharp';
import ScheduleSharpIcon from '@mui/icons-material/ScheduleSharp';
import { useFetchApi } from '../utils/UseFetchApi';
import {
    IJob,
    IOutputDetails,
    IInputDetails,
    useJobActions,
    JobState,
    JobOutcome,
    JobLink,
    isJobActiveOrQueued,
} from "../Model/Job";
import CenteredProgress from './CenteredProgress';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useInterval from '../utils/UseInterval';
import { IJobSteps, IJobStep } from '../Model/JobSteps';
import JobStepDetails from './JobStepDetails';
import { dateStringToLocaleString } from '../utils/DateUtils';
import { CenteredText, downloadUri, hasAdminOrViewOnlyAccess } from '../utils/Utils';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { JobLogDetails } from 'realityservices-ui-components';
import { useAuthorizationContext } from '../utils/AuthorizationContext';
import { JobStepsRefreshInterval, QueuedJobRefreshInterval } from "../utils/Constants";


const StyledDivRoot = styled('div')((props)=> ({
    backgroundColor: props.theme.palette.background.paper,
}))

const StyledDivSpacing = styled('div')((props)=> ({
    padding: props.theme.spacing(1),
}))

const StyledTableCell = styled(TableCell)((props)=> ({
    border: '0px'
}))

const StyledTableRow = styled(TableRow)((props)=> ({
    border: '0px'
}))


const BorderLinearProgress = styled(LinearProgress)({
    height: 25,
    width: 600,
    borderRadius: 20,

    '& .MuiLinearProgress-bar': {
        borderRadius: 20,
    },
});

enum ETypography {
    None,
    Standard,
    NoWrap,
}

interface IJobRow {
    label: string;
    fill: any;
    typography: ETypography;
    showLoader?: boolean;
}

interface SasUri {
    sasUri: string;
}

interface IJobInfoDisplayProps {
    job: IJob;
    refreshJobInfo: () => void;
}

function JobInfoDisplay({ job, refreshJobInfo }: IJobInfoDisplayProps) {
    const jobStorageSas = useFetchApi<SasUri>(`${window.location.origin}/api/v2/jobs/${job.id}/sas-uri`);
    const jobStepsFetch = useFetchApi<IJobSteps>(
        `${window.location.origin}/api/v2/jobs/${job.id}/steps`,
        undefined,
        job.state !== JobState.Queued
    );
    const runningTaskApi = useFetchApi<any>(`${window.location.origin}/api/v2/jobs/${job.id}/runningTasks`);
    const failedTaskApi = useFetchApi<any>(`${window.location.origin}/api/v2/jobs/${job.id}/failedTasks`);
    const [selectedStep, setSelectedStep] = React.useState<IJobStep | undefined>(undefined);
    const jobActions = useJobActions();

    const [runningTasksInfo, setRunningTasksInfo] = React.useState<string>("Not yet queried");
    const [failedTasksInfo, setFailedTasksInfo] = React.useState<string[] | string>("Not yet queried");

    const frontendAccessLevel = useAuthorizationContext();
    const isAdminOrViewOnlyUser = hasAdminOrViewOnlyAccess(frontendAccessLevel);

    React.useEffect(() => {
        if (isAdminOrViewOnlyUser && job.state !== JobState.New) jobStorageSas.run();
    }, [isAdminOrViewOnlyUser]);

    React.useEffect(() => {
        if ((job.state === JobState.Active || job.state === JobState.Completed)
            && !jobStepsFetch.data
        ) {
            fetchJobSteps();
        }
    }, [job]);

    function fetchJobSteps() {
        jobStepsFetch.run().then((steps) => {
            if (job.state !== JobState.Completed && steps?.activeIndex === -1) refreshJobInfo();
        });
    }

    function GetButtons() {
        var butLogs = (<Button variant="contained" color="primary" size="small"
            startIcon={<GetAppSharpIcon />} disableElevation onClick={(event) => handleDownloadJobLogs(event)}>
            Logs
        </Button>);
        var butKill = (<Button variant="contained" style={{color: grey[900], backgroundColor: orange[200]}} size="small"
            startIcon={<CancelOutlineIcon />} disableElevation onClick={() => jobActions.terminate(job)}>
            Terminate
        </Button>);

        return (
            <div>
                {job.state !== JobState.New && butLogs}
                &nbsp;
                {isJobActiveOrQueued(job) && butKill}
            </div>
        );
    }

    useInterval(() => {
        if (jobStepsFetch.error) {
            return;
        }
        fetchJobSteps();
    }, job.state === JobState.Active ? JobStepsRefreshInterval : null);

    useInterval(refreshJobInfo, job.state === JobState.Queued ? QueuedJobRefreshInterval : null);

    const handleStepClick = (event: React.MouseEvent<unknown>, step: IJobStep) => {
        setSelectedStep(step);
    };

    const handleDownloadJobLogs = (event: React.MouseEvent<unknown>) => {
        jobActions.downloadLogs(job);
    };

    const handleDownloadTaskLogs = (taskId: string) => {
        const taskLog = `/${taskId}/$TaskLog/Task_${taskId}.log`;
        let uri = jobStorageSas?.data?.sasUri.replace("?", `${taskLog}?`) ? jobStorageSas?.data?.sasUri.replace("?", `${taskLog}?`) : "";
        downloadUri(uri);
    };

    const getRunningTasks = (event: React.MouseEvent<unknown>) => {
        runningTaskApi.run()
            .then(result => {
                if (result.length > 0)
                    setRunningTasksInfo(`${result.length} tasks running: ${result.join(", ")}`);
                else
                    setRunningTasksInfo("No running task");
            })
            .catch(error => {
                setRunningTasksInfo(`Failed to get running tasks for ${job.name}: ${error.toString()}`);
            });
    };

    const getFailedTasks = (event: React.MouseEvent<unknown>) => {
        failedTaskApi.run()
            .then(result => {
                if (result.length > 0)
                    setFailedTasksInfo(result);
                else
                    setFailedTasksInfo("No failed task");
            })
            .catch(error => {
                setFailedTasksInfo(`Failed to get failed tasks for ${job.name}: ${error.toString()}`);
            });
    };

    function createRow(jobRow: IJobRow)
    {
        let filling: any;
        switch (jobRow.typography)
        {
            case ETypography.None:
                filling = jobRow.fill;
                break;

            case ETypography.Standard:
                filling = <Typography>{jobRow.fill}</Typography>;
                break;

            case ETypography.NoWrap:
                filling= <Typography noWrap>{jobRow.fill}</Typography>;
                break;
        }

        return (
            <StyledTableRow>
                <StyledTableCell>
                    <strong>{jobRow.label}</strong>
                </StyledTableCell>
                <StyledTableCell>
                    {jobRow.showLoader ? <CircularProgress size={16} /> : filling}
                </StyledTableCell>
            </StyledTableRow>
        );
    }

    function jobInfoHeader() {
        const jobInfoHeaderRows: IJobRow[] = [
            { label: "ID:", fill: job.id, typography: ETypography.NoWrap },
            {
                label: "iTwin:",
                fill: job.connectProjectId ?? "N/A",
                typography: ETypography.NoWrap,
            },
            { label: "Type:", fill: job.type, typography: ETypography.NoWrap },
            {
                label: "State:",
                fill:
                    job.state === JobState.Queued
                        ? `${JobState.Active} (${JobState.Queued})`
                        : job.state,
                typography: ETypography.NoWrap,
            },
            {
                label: "Outcome:",
                fill: job.executionInformation?.outcome ?? "N/A",
                typography: ETypography.NoWrap,
                showLoader: isJobActiveOrQueued(job),
            },
            {
                label: "Location:",
                fill: job.dataCenter.location,
                typography: ETypography.NoWrap,
            },
        ];

        if (job.state !== "New" && isAdminOrViewOnlyUser) {
            jobInfoHeaderRows.push({ label: "Actions:", fill: GetButtons(), typography: ETypography.None });
        }

        return (
            <Table size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    <StyledTableCell colSpan={2}>
                        <Typography variant="h6">
                            Job details:
                        </Typography>
                    </StyledTableCell>
                    {jobInfoHeaderRows.map((jobInfoHeaderRow) => createRow(jobInfoHeaderRow))}
                </TableBody>
            </Table>
        )
    }

    function outputDetailsList(details: IOutputDetails[]) {
        var formats:string[] = [];
        for(var i = 0; i < details.length; i++) {
            formats.push(details[i].format || "Unknown");
        }
        return (
            <div>{formats.join(", ")}</div>
        );
    }

    function getDuration(timeInMilliseconds: number) {
        let seconds = Math.floor((timeInMilliseconds / 1000) % 60),
            minutes = Math.floor((timeInMilliseconds / (1000 * 60)) % 60),
            hours = Math.floor((timeInMilliseconds / (1000 * 60 * 60)) % 24),
            days = Math.floor((timeInMilliseconds / (1000 * 60 * 60 * 24)));

        let formatted = `${minutes}m ${seconds}s`;

        if (days > 0) {
            formatted = `${days}d ${hours}h ` + formatted;
        } else if (hours > 0) {
            formatted = `${hours}h ` + formatted;
        }

        return formatted;
    }

    function resultDetailsDisplay() {
        let startTime = job.executionInformation?.startTime;
        let endTime = job.executionInformation?.endTime;
        var runTime = -1;
        var computeTime = job.executionInformation?.computeTime ?? -1;

        if (endTime && startTime) {
            runTime = Date.parse(endTime) - Date.parse(startTime);
        }
        endTime = endTime ? dateStringToLocaleString(endTime) : "N/A";
        startTime = startTime ? dateStringToLocaleString(startTime) : "N/A";

        const resultRows: IJobRow[] = [
            { label: "Start time:", fill: startTime, typography: ETypography.Standard, showLoader: isJobActiveOrQueued(job) && startTime === "N/A" },
            { label: "End time:", fill: endTime, typography: ETypography.Standard, showLoader: isJobActiveOrQueued(job) },
            { label: "Run time:", fill: runTime === -1 ? "N/A" : getDuration(runTime), typography: ETypography.Standard, showLoader: isJobActiveOrQueued(job) },
            { label: "Compute time: ", fill: computeTime === -1 ? "N/A" : getDuration(computeTime * 1000), typography: ETypography.Standard, showLoader: isJobActiveOrQueued(job) },
            { label: "Exit code:", fill: job?.executionInformation?.exitCode ?? "N/A", typography: ETypography.Standard, showLoader: isJobActiveOrQueued(job) }
        ];

        return(
            <Table size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    {resultRows.map((resultRow) => createRow(resultRow))}
                </TableBody>
            </Table>
        )
    }

    function inputDetailsList(details: IInputDetails[]) {
        const listItems = details.map((detail, index) =>
        {
            const inputRows: IJobRow[] = [
                {label: "Type:", fill: detail.type, typography: ETypography.NoWrap},
                {label: "Description:", fill: detail.description, typography: ETypography.Standard},
                {label: "Id:", fill: detail.id, typography: ETypography.NoWrap}
            ];

            return (<Table size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    <StyledTableRow>
                        <Typography variant="h6">
                            Input {index+1}:
                        </Typography>
                    </StyledTableRow>
                    {inputRows.map((inputRow) => createRow(inputRow))}
                </TableBody>
            </Table>);
        });
        return (
            <div>{listItems}</div>
        );
    }

    function jobSettingsDisplay() {
        const settings = job.settings;
        const outputDetails: IOutputDetails[] = settings?.outputs ? settings?.outputs : [];
        const settingsRows: IJobRow[] = [
            { label: "Mesh quality:", fill: settings?.meshQuality, typography: ETypography.NoWrap },
            { label: "Output types:", fill: outputDetailsList(outputDetails), typography: ETypography.Standard },
            { label: "Processing engines:", fill: settings?.processingEngines, typography: ETypography.NoWrap }
        ];

        if (settings?.cacheSettings?.createCache) {
            settingsRows.push({ label: "Create Cache:", fill: settings?.cacheSettings.createCache.toString(), typography: ETypography.Standard });
        }
        if (settings?.cacheSettings?.useCache) {
            settingsRows.push({ label: "Use Cache:", fill: <JobLink id={settings?.cacheSettings?.useCache} />, typography: ETypography.Standard });
        }

        return (
            <Table size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    {settingsRows.map((settingsRow) => createRow(settingsRow))}
                </TableBody>
            </Table>
        )
    }

    function inputInfoDisplay() {
        const inputInfo = job.executionInformation?.inputInformation!;

        const photoRows: IJobRow[] = [
            { label: "Photo count:", fill: inputInfo.photoCount, typography: ETypography.NoWrap },
            { label: "Input GPix:", fill: inputInfo.gigaPixelsAT, typography: ETypography.NoWrap },
            { label: "Reconstruction GPix:", fill: inputInfo.gigaPixelsRecons, typography: ETypography.NoWrap }
        ];

        const photoInfo = inputInfo.photoCount === 0 ? "" : (
            <>
                {photoRows.map((photoRow) => createRow(photoRow))}
            </>);

        const pointCloudRows: IJobRow[] = [
            { label: "Pointcloud count:", fill: inputInfo.pointCloudCount, typography: ETypography.NoWrap },
            { label: "Input megapoints:", fill: inputInfo.megaPoints, typography: ETypography.NoWrap },
            { label: "Recons megapoints:", fill: inputInfo.megaPointsRecons, typography: ETypography.NoWrap }
        ];

        const pointCloudInfo = inputInfo.pointCloudCount === 0 ? "" : (
            <>
                {pointCloudRows.map((pointCloudRow) => createRow(pointCloudRow))}
            </>);

        return (
            <Table size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    {photoInfo}
                    {pointCloudInfo}
                    {createRow({
                        label: "Cache:",
                        fill: (<>
                            AT: <i>{inputInfo.calibrationFromCache?.toString()}</i> <br />
                            Reconstruction: <i>{inputInfo.reconstructionFromCache?.toString()}</i>
                        </>),
                        typography: ETypography.Standard
                    })}
                </TableBody>
            </Table>
        );
    }

    function submissionDetailsDisplay() {
        let submissionTime = job.submissionDetails?.time;
        let startTime = job.executionInformation?.startTime;
        let waitTime = -1;

        if (submissionTime && startTime) {
            waitTime = Date.parse(startTime) - Date.parse(submissionTime);
        }
        submissionTime = submissionTime ? dateStringToLocaleString(submissionTime) : "N/A";
        startTime = startTime ? dateStringToLocaleString(startTime) : "N/A";

        const submissionDetailsRows: IJobRow[] = [
            { label: "Email:", fill: job.userDetails.email, typography: ETypography.Standard },
            { label: "Ultimate site:", fill: job.ultimateSite, typography: ETypography.NoWrap },
            { label: "Creation time:", fill: dateStringToLocaleString(job.creationTime), typography: ETypography.Standard },
            { label: "User-agent", fill: job.submissionDetails?.userAgent ?? "N/A", typography: ETypography.Standard },
            { label: "Pool:", fill: job.submissionDetails?.clusterId ?? "N/A", typography: ETypography.Standard, showLoader: job.state === JobState.Queued },
            { label: "Submission time:", fill: submissionTime, typography: ETypography.Standard },
            { label: "Start time:", fill: startTime, typography: ETypography.NoWrap, showLoader: isJobActiveOrQueued(job) && startTime === "N/A" },
            { label: "Wait time:", fill: waitTime === -1 ? "N/A" : getDuration(waitTime), typography: ETypography.NoWrap, showLoader: isJobActiveOrQueued(job) && startTime === "N/A" }
        ];

        return (
            <Table size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    <StyledTableCell colSpan={2}>
                        <Typography variant="h6">
                            Submission details:
                        </Typography>
                    </StyledTableCell>
                    {submissionDetailsRows.map((submissionDetailsRow) => createRow(submissionDetailsRow))}
                </TableBody>
            </Table>
        )
    }

    function getJobStateIcon() {
        const outcome = job.executionInformation?.outcome ?? 'N/A';
        if (outcome === JobOutcome.Success)
            return <CheckCircleOutlineSharpIcon fontSize="large" style={{ color: green[500] }} />;
        else if (outcome === JobOutcome.Failed)
            return <HighlightOffSharpIcon fontSize="large" style={{ color: red[500] }} />;
        else if (outcome === JobOutcome.Cancelled)
            return <BlockOutlined fontSize="large" style={{ color: grey[500] }} />;
        else if (job.state === JobState.Active)
            return <LoopSharpIcon fontSize="large" color="secondary" />;
        else if (job.state === JobState.Queued)
            return <ScheduleSharpIcon fontSize="large" style={{ color: grey[800] }} />;
        else
            return <AssignmentSharpIcon fontSize="large" style={{ color: grey[500] }} />;
    }

    function jobDetailsExpansionPanel() {
        const inputDetails: IInputDetails[] = job?.inputs ?? [];
        return (
            <>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h6">Results and job settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} direction="row">
                            <Grid item lg={4} md={6}>
                                {resultDetailsDisplay()}
                            </Grid>
                            <Grid item lg={4} md={6}>
                                {jobSettingsDisplay()}
                            </Grid>
                            {job.executionInformation?.inputInformation &&
                                (<Grid item lg={4} md={6}>
                                    {inputInfoDisplay()}
                                </Grid>
                                )}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                {job.state === JobState.Active && isAdminOrViewOnlyUser &&(
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="h6">Running tasks</Typography>
                        </AccordionSummary>
                        <AccordionDetails>{runningTasks()}</AccordionDetails>
                    </Accordion>
                )}
                {job.executionInformation?.outcome === JobOutcome.Failed && isAdminOrViewOnlyUser && (
                    <>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6">
                                    Failed tasks
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>{failedTasks()}</AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6">Logs</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JobLogDetails errors={job.executionInformation?.errors ?? []} warnings={job.executionInformation?.warnings ?? []} />
                            </AccordionDetails>
                        </Accordion>
                    </>
                )}
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h6">Step details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {job.state !== JobState.New ? stepDisplayTable() : "Job not submitted yet."}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h6">Input details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {inputDetailsList(inputDetails)}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    function runningTasks() {
        return (<span><Button variant="contained" color="secondary" disableElevation size="small" startIcon={<GetAppSharpIcon />} onClick={(event) => getRunningTasks(event)}>
            Get running tasks
        </Button>&nbsp;{runningTasksInfo}</span>)
    }

    function failedTasks() {
        let message: string | JSX.Element;

        if (typeof(failedTasksInfo) === 'string')
            message = failedTasksInfo
        else {
            message = (
                <span>
                    {failedTasksInfo.map(t =>
                        <Button variant="contained" color="secondary" disableElevation size="small" startIcon={<GetAppSharpIcon />} onClick={(event) => handleDownloadTaskLogs(t.substring(0, t.indexOf(':')))}>
                            {t}
                        </Button>
                    )}
                </span>);
        }

        return (<span><Button variant="contained" color="secondary" disableElevation size="small" startIcon={<GetAppSharpIcon />} onClick={(event) => getFailedTasks(event)}>
            Get failed tasks
        </Button>&nbsp;{message}</span>)
    }

    function stepDisplayTable() {
        if (!jobStepsFetch.data && jobStepsFetch.isFetching) return <CenteredProgress />;

        if (job.state === JobState.Queued)
            return (<div>{`Job is waiting in queue.`}</div>);

        if (jobStepsFetch.error)
            return (<div>{`${jobStepsFetch.error}`}</div>);

        if (!jobStepsFetch.data)
            return (<div>{`Job(${job.id}) steps not found.`}</div>);

        return (
            <TableContainer >
                <Table size="small" >
                    <TableBody>
                        {jobStepsFetch.data?.steps.map((jobStep, index) =>
                            <StyledTableRow
                                key={index}
                                selected={selectedStep === jobStep}
                                onClick={(event) => handleStepClick(event, jobStep)}
                            >
                                <StyledTableCell style={{ width: 256 }}>
                                    {stepDisplay(jobStep, index === jobStepsFetch.data?.activeIndex)}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: "1100px" }}>
                                        <Box sx={{ width: '100%', mr: 1.25 }}>
                                            <LinearProgress variant="determinate" color="secondary" value={jobStep.percentage} />
                                        </Box>
                                        <Box sx={{ width: 40 }}>
                                            {index === jobStepsFetch.data?.activeIndex &&
                                                <strong>{Math.round(jobStep.percentage * 100) / 100}%</strong>}
                                        </Box>
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow >
                        )}
                    </TableBody>
                </Table>
                {selectedStep && isAdminOrViewOnlyUser ? <JobStepDetails job={job} stepName={selectedStep.name} /> : <div></div>}
            </TableContainer>
        )
    }

    function stepDisplay(step: IJobStep, isActive: boolean) {
        const stepText = `${step.name} (${step.taskCount ?? '?'})`;
        if (isActive)
            return <strong>{stepText}</strong>;
        return stepText;
    }

    return (
        <StyledDivSpacing >
            <TableContainer >
                <Table size="small" style={{ width: "auto", tableLayout: "auto" }}>
                    <StyledTableCell>
                        {getJobStateIcon()}
                    </StyledTableCell>
                    <StyledTableCell>
                        <Typography noWrap variant="h4">
                            {job.name}
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                        <Typography>
                            {jobStepsFetch.hasData && <BorderLinearProgress variant="determinate" color="secondary" value={jobStepsFetch.data?.percentage} />}
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                        <Typography noWrap variant="h5" style={{ width: 90 }}>
                            {jobStepsFetch.hasData && `${jobStepsFetch.data?.percentage}%`}
                        </Typography>
                    </StyledTableCell>
                </Table>
                <Grid container spacing={1} direction="row">
                    <Grid item lg={6}>
                        {jobInfoHeader()}
                    </Grid>
                    <Grid item lg={6}>
                        {submissionDetailsDisplay()}
                    </Grid>
                </Grid>
                {jobDetailsExpansionPanel()}
            </TableContainer>
        </StyledDivSpacing>
    );
}

export default function JobDetails() {
    const { id: jobId } = useParams();

    const jobFetch = useFetchApi<IJob>(
        `${window.location.origin}/api/v2/jobs/${jobId}/internal`
    );

    React.useEffect(() => {
        jobFetch.run();
    }, [jobId]);

    function refreshJob() {
        jobFetch.run();
    }

    if (jobFetch.fetchCount === 0 || (jobFetch.data?.id !== jobId && jobFetch.isFetching))
        return (<CenteredProgress />);

    if (jobFetch.error)
        return (<CenteredText text={`${jobFetch.error}`} />);

    if (!jobFetch.data)
        return (<CenteredText text={`Job ${jobId} not found`} />);

    return (
        <StyledDivRoot>
            <StyledDivSpacing>
                <JobInfoDisplay
                    job={jobFetch.data}
                    refreshJobInfo={refreshJob}
                />
            </StyledDivSpacing>
        </StyledDivRoot>
    );
}
